import { shareSNS } from './lib/_share.js';
import { Scroll } from './lib/_scroll.js';

export var common = {
	scrl: "",
	beforeW: 0,
	init: ()=>{
		common.start();
	},
	start: function(){
		let resizeFlag;
		let resizeTimer = null;
		let $body = document.getElementsByTagName('body')[0];
		
		common.resize();
		common.nav();
		common.scrl = new Scroll();
		
		/* window resize
		----------------------------- */
		var resizeCallback = function() {
			common.resize();
			resizeTimer = null;
		};
		window.addEventListener("resize", function(){
			if( !$body.classList.contains("resize") ){
				$body.classList.add("resize");
			}
			if(resizeTimer) {
				clearTimeout(resizeTimer);
			}
			resizeTimer = setTimeout(resizeCallback, 300);
		}, false);
	},
	nav: ()=>{
		common.mail();
		
	},
	mail: function(){
		let contact = document.querySelector(".link_mail");
		if(contact){
			contact.innerText = converter(String.fromCharCode(104,109,101,110,63,100,107,96,109,108,104,115,114,116,96,45,98,110,108));
			contact.addEventListener("click", function(){
				mail_to("", "");
			}, false);
			function converter(M){
				var str="", str_as="";
				for(var i=0;i<M.length;i++){
					str_as = M.charCodeAt(i);
					str += String.fromCharCode(str_as + 1);
				}
				return str;
			}
			/*
			function mail_to(k_1,k_2){
				eval(String.fromCharCode(108,111,99,97,116,105,111,110,46,104,114,101,102,32,61,32,39,109,97,105,108,116,111,58) + escape(k_1) + converter(String.fromCharCode(114,116,111,111,110,113,115,63,114,110,116,109,99,44,110,109,100,45,110,109,110,114,110,106,106,104,45,98,110,45,105,111,62,114,116,97,105,100,98,115,60)) + escape(k_2) + "'");
			}
			*/
			function mail_to(k_1,k_2){
				eval(String.fromCharCode(108,111,99,97,116,105,111,110,46,104,114,101,102,32,61,32,39,109,97,105,108,116,111,58) + escape(k_1) + converter(String.fromCharCode(104,109,101,110,63,100,107,96,109,108,104,115,114,116,96,45,98,110,108,62,114,116,97,105,100,98,115,60)) + escape(k_2) + "'");
			} 
		}
	},
	resize: ()=>{
		var winW 	= window.innerWidth;
		var winH 	= window.innerHeight;
		let $body 	= document.getElementsByTagName('body')[0];
		
		addressbarH();
		
		setTimeout(function(){
			if( $body.classList.contains("resize") ){
				$body.classList.remove("resize");
			}
		},300);
		function addressbarH(){
			if(common.beforeW != winW){
				// 100vh、アドレスバーが出てる時に対応
				let vh = window.innerHeight * 0.01;
				document.documentElement.style.setProperty("--vh", `${vh}px`);
			}
		}
		common.beforeW = winW;
	},
	mouseCursor: function(){
		window.document.addEventListener("mousemove", getMousePos, false);
		function getMousePos(e){
			mouse[0] = e.clientX;
			mouse[1] = e.clientY;
		}
	},
	removeMultipleClass: function(dom, className){
		const target = document.querySelector(dom);
		const targetClassList = target.className;
		const myRegExp = new RegExp("/\b" + className + "\S+/", 'g');
		const myMatched = targetClassList.match(myRegExp) || [];
		for(let n = 0; n < myMatched.length; n++) {
		  target.classList.remove(myMatched[n]);
		}
	}
}
