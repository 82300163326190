import { preload } from './lib/_preload.js';
import { loc } from './lib/_location.js';
import { common } from './_common.js';


export class Framework {
	constructor(){
		this.firstVisit = true;
		this.canClick 	= true;
		this.pageinfo = loc.all();
		this.nextpage = "";
		this.checkURL();
	}
	
	checkURL(){
		if(this.canClick){
			this.canClick = false;
			//path
			this.changeContent();
		}
	}
	
	nav(){
		
	}
	
	changeURL(){
		
	}
	
	changeContent(){
		this.afterChangePage();
	}
	
	afterChangePage(){
		if(this.firstVisit){
			this.firstVisit = false;
			common.init();
		}
		common.resize();
		common.nav();
		this.reset();
		this.nav();
		let $body = document.querySelector("body");
		if( $body.classList.contains("disable") ){
			$body.classList.remove("disable");
			setTimeout(function(){
				document.querySelector("#block_kv").classList.add("active");
			}, 600);
		}
	}
	
	reset(){
		this.canClick = true;
	}
}