import { object } from './_obj.js';
import { gsap } from "gsap";
import { ScrollTrigger } from "../gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
/*
座標
elem.getBoundingClientRect()
*/
let $this;
export class Scroll {
	constructor(){
		$this = this;
		this.topD = 0;
		this.btmD = this.topD + window.innerHeight;
		this.animations = new Array();
		this.blocks 	= new Array();
		this.start();
	}
	start(){
		//全.aの監視
		gsap.utils.toArray(".a").forEach( (el, index) => {
			gsap.from(el, {
				scrollTrigger: {
					trigger: el,
					start: "top 70%",
					end: "bottom top",
					marker: true,
					onEnter: (self) => {
						if(!el.classList.contains("animate")){
							el.classList.add("animate");
						}
					},
					onLeaveBack: ()=>{
						if(el.classList.contains("animate")){
							el.classList.remove("animate");
						}
					}
				}
			})
		});
		gsap.to("#bg", {
			y: `-200px`,
			scrollTrigger: {
				trigger: 'main',
				start: 'top bottom',
				end: `+=${document.querySelector("main").clientHeight}`,
				invalidateOnRefresh: true,
				scrub: 4
			}
		});
		gsap.to("#bg_paper .p0", {
			backgroundPosition: `right ${document.querySelector("main").clientHeight/16}`,
			scrollTrigger: {
				trigger: 'main',
				start: 'top bottom',
				end: `+=${document.querySelector("main").clientHeight}`,
				invalidateOnRefresh: true,
				scrub: 4
			}
		});
		gsap.to("#bg_paper .p1", {
			backgroundPosition: `right ${document.querySelector("main").clientHeight/8}`,
			scrollTrigger: {
				trigger: 'main',
				start: 'top bottom',
				end: `+=${document.querySelector("main").clientHeight}`,
				invalidateOnRefresh: true,
				scrub: 4
			}
		});
	}
}

export let ScrollTo = {
	begin: 0,
	yOffset: 0,
	end: 0,
	timer: null,
	start: function(y, duration){
		let $this = ScrollTo;
		$this.end = y;
		//console.log(y, duration);
		$this.begin = new Date() - 0;
		$this.yOffset = window.pageYOffset;
		//console.log($this.yOffset, y);
		
		clearInterval($this.timer);
		let ease = getEasing("easeInOutCubic");
		let max = y - window.pageYOffset;
		$this.timer = setInterval( ()=> {
			let current = new Date() - $this.begin;
			if(current >= duration){
				current = duration;
				clearInterval($this.timer);
			}
			//let progress = 1 - current/duration;
			let progress = current/duration;
			let easeProgress = ease(progress, 0, 1, 1);
			//console.log(easeProgress);
			let currentPos = max*easeProgress + $this.yOffset;
			//console.log(max*easeProgress, window.pageYOffset);
			window.scrollTo(0, currentPos );
		}, 33);
	}
}